import { default as abuseReports9umSSYl8RSMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93DKXPnQq988Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexU1AqUGvcXDMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/index.vue?macro=true";
import { default as moderationEs5auPLOc1Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/moderation.vue?macro=true";
import { default as pendingBansL1Vadpj4WpMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/pendingBans.vue?macro=true";
import { default as profiles2PdFAzMvyGMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/profiles.vue?macro=true";
import { default as expensesMANMVeSxA3Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexOwFckH3gt4Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/timesheets/index.vue?macro=true";
import { default as overview2jizVI250AMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingC8WX6bXRxFMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingJwwGxMTyl8Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/translations/missing.vue?macro=true";
import { default as usersnDpQpkpbSGMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/users.vue?macro=true";
import { default as apisQD5FXK9ZIMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/api.vue?macro=true";
import { default as _91slug_93Xm6cvyOO0IMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/blog/[slug].vue?macro=true";
import { default as index824w0YdZz6Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93kF6qpo90oxMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93KkK5hFGLT3Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as admin5kbqE1NSe1Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/census/admin.vue?macro=true";
import { default as indexJHsrmGtrfyMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/census/index.vue?macro=true";
import { default as contactXN4izdpOSjMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/contact.vue?macro=true";
import { default as designD25oBrYUapMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/design.vue?macro=true";
import { default as englishRmxOOdEqJoMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/english.vue?macro=true";
import { default as faqTWILBVMK2rMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/faq.vue?macro=true";
import { default as inclusiveTAU23hWhfuMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/inclusive.vue?macro=true";
import { default as indexQ0MxsKnCUpMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/index.vue?macro=true";
import { default as licensevl56qNUAzzMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/license.vue?macro=true";
import { default as academicxnoAlnaQVLMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/links/academic.vue?macro=true";
import { default as index5eRSScYnzQMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/links/index.vue?macro=true";
import { default as mediaRbX4evG0fKMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/links/media.vue?macro=true";
import { default as translinguisticsr48LUrOd6NMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/links/translinguistics.vue?macro=true";
import { default as zineBmDxLJypTNMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/links/zine.vue?macro=true";
import { default as namesL4KEOfX6OKMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/names.vue?macro=true";
import { default as indexsSk5T11giaMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/nouns/index.vue?macro=true";
import { default as templateskiYbZdHiEoMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/nouns/templates.vue?macro=true";
import { default as peoplerGfPuFHaFFMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/people.vue?macro=true";
import { default as privacyIk57zmlArNMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/privacy.vue?macro=true";
import { default as _91username_93ZfzyMkmkEAMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_936E3pgabW7YMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/profile/card-[username].vue?macro=true";
import { default as editorxFKX71LdTtMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/profile/editor.vue?macro=true";
import { default as anygwNImSCkPuMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/any.vue?macro=true";
import { default as askd7mmM3s3jcMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/ask.vue?macro=true";
import { default as avoidingN9GZmqTfPhMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/avoiding.vue?macro=true";
import { default as indexzRMpItSb1hMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/index.vue?macro=true";
import { default as mirror6bWvPgE8g6Meta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun3PEMU0JgBiMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/pronoun.vue?macro=true";
import { default as sources9yjMbLkrmGMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/sources.vue?macro=true";
import { default as team54xtrw50kSMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/team.vue?macro=true";
import { default as terminologyRkiTK4QBnDMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/terminology.vue?macro=true";
import { default as termsIGxAAzHbIxMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/terms.vue?macro=true";
import { default as userbv9TDZwTnyMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/user.vue?macro=true";
import { default as workshopsihRlj1sdwcMeta } from "/home/admin/www/pronomejo.net/release/20241202171339/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports9umSSYl8RSMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansL1Vadpj4WpMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apisQD5FXK9ZIMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93Xm6cvyOO0IMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: index824w0YdZz6Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93kF6qpo90oxMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93KkK5hFGLT3Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: admin5kbqE1NSe1Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexJHsrmGtrfyMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactXN4izdpOSjMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishRmxOOdEqJoMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqTWILBVMK2rMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveTAU23hWhfuMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicxnoAlnaQVLMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index5eRSScYnzQMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaRbX4evG0fKMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsr48LUrOd6NMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineBmDxLJypTNMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesL4KEOfX6OKMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexsSk5T11giaMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templateskiYbZdHiEoMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplerGfPuFHaFFMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyIk57zmlArNMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93ZfzyMkmkEAMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_936E3pgabW7YMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorxFKX71LdTtMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anygwNImSCkPuMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askd7mmM3s3jcMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingN9GZmqTfPhMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexzRMpItSb1hMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirror6bWvPgE8g6Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun3PEMU0JgBiMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources9yjMbLkrmGMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team54xtrw50kSMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyRkiTK4QBnDMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsIGxAAzHbIxMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userbv9TDZwTnyMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsihRlj1sdwcMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241202171339/pages/workshops.vue")
  }
]